import React from 'react'
import './global-components.css';
import '../global.css';

export const Explore = () => {
    return (
        <>
            <div className='container explore-main'>
                <div className='row'>
                    <div className='explore-head'>
                        <div className='explore-title'>
                            <img src='https://stg-api.idflow.com/v1/files/wz6Uw59stv2Nx-GVttDS3' />
                            <h3>Explore</h3>
                            <p>Seamlessly search and explore a wide range of content to enhance your workflow building experience.</p>
                        </div>
                        <div className='explore-search'>
                            <input type='text' className='search' placeholder='Search' />
                            {/* <img src='https://stg-api.idflow.com/v1/files/FadfjY3wJ8ZtcsNq2hhjs' /> */}
                        </div>
                        <div className='tab-buttons'>
                            <button>India</button>
                            <button>Global</button>
                            <button>MENA</button>
                            <button>ASEAN</button>
                            <button>KYC</button>
                            <button>AML</button>
                            <button>Alternate Score</button>
                            <button>ASEAN</button>
                            <button>KYC</button>
                            <button>Alternate Score</button>
                            <button>Global</button>
                            <button>India</button>
                        </div>
                        <img src='https://stg-api.idflow.com/v1/files/BheDIeAuiM0W1XqPtMWxj' className='highlight-three' />
                        <img src='https://stg-api.idflow.com/v1/files/Pp3MW5uCupV_sea_6e9kF' className='highlight-two' />
                    </div>
                </div>
                <div className=' add-new'>
                    <div className='new-work-flow'>
                        <div className='new-workflow-head'>
                            <img src='https://stg-api.idflow.com/v1/files/PA6DI6O-PaREOP649iDQ3' />
                            <h5 className='mb-0'>Create New Flow</h5>
                        </div>
                        <p className='mb-0'>Build custom workflows for WhatsApp, API backend, and web UI with one-click deployments</p>
                        <a href='https://m2p.idflow.site/home/workflows' className='new-button'><img src='https://stg-api.idflow.com/v1/files/ema4wgSfZ8bxRiSIYwC9l' />&nbsp;Create Custom Workflow</a>
                    </div>

                    <div className='new-work-flow'>
                        <div className='new-workflow-head'>
                            <img src='https://stg-api.idflow.com/v1/files/kUXAwaKhcPWCH4Xm1lSaV' />
                            <h5 className='mb-0'>Create New Node</h5>
                        </div>
                        <p className='mb-0'>Build custom workflows for WhatsApp, API backend, and web UI with one-click deployments</p>
                        <a className='new-button' href='https://m2p.idflow.site/home/nodes'><img src='https://stg-api.idflow.com/v1/files/ema4wgSfZ8bxRiSIYwC9l' />&nbsp;Create New Nodes</a>
                    </div>

                </div>
                {/* Existing Flows */}
                <div className='existing-flows'>
                    <h3 className='mt-4 pb-2'>Existing workflows</h3>
                    <div className='existing'>
                        <div className='existing-flow'>
                            <img src='https://stg-api.idflow.com/v1/files/kUXAwaKhcPWCH4Xm1lSaV' />
                            <h5 className='mb-0'>Alternate Score</h5>
                            <p className='mb-0'>Lorem ipsum dolor sit amet consectetur. Tincidunt pellentesque amet tempor in a commodo.</p>
                            <button className='new-button'>Try it Out</button>
                        </div>
                        <div className='existing-flow'>
                            <img src='https://stg-api.idflow.com/v1/files/kUXAwaKhcPWCH4Xm1lSaV' />
                            <h5 className='mb-0'>Alternate Score</h5>
                            <p className='mb-0'>Lorem ipsum dolor sit amet consectetur. Tincidunt pellentesque amet tempor in a commodo.</p>
                            <button className='new-button'>Try it Out</button>
                        </div>
                        <div className='existing-flow'>
                            <img src='https://stg-api.idflow.com/v1/files/kUXAwaKhcPWCH4Xm1lSaV' />
                            <h5 className='mb-0'>Alternate Score</h5>
                            <p className='mb-0'>Lorem ipsum dolor sit amet consectetur. Tincidunt pellentesque amet tempor in a commodo.</p>
                            <button className='new-button'>Try it Out</button>
                        </div>
                        <div className='existing-flow'>
                            <img src='https://stg-api.idflow.com/v1/files/kUXAwaKhcPWCH4Xm1lSaV' />
                            <h5 className='mb-0'>Alternate Score</h5>
                            <p className='mb-0'>Lorem ipsum dolor sit amet consectetur. Tincidunt pellentesque amet tempor in a commodo.</p>
                            <button className='new-button'>Try it Out</button>
                        </div>
                    </div>
                </div>

                {/* Customer Stories */}
                <div className='customer-stories'>
                    <h3 className='mt-4 pb-2'>Customer Stories</h3>
                    <div className='cus-stories-div'>
                        <div className='customer-story'>
                            <div className='story-btns'>
                                <button>Payments</button>
                                <button>Global Market</button>
                            </div>
                            <p>We have increased our workflows effiency by 15% IDFlow .</p>
                            <div className='lq-div'>
                                <h3>LIV<span>Q</span>UIK</h3>
                                <button>View Complete Story</button>
                            </div>
                            <img src="https://stg-api.idflow.com/v1/files/g0VF5ZwBHOKyvDnWZLmoI" />
                        </div>
                        <div className='customer-story-two next'>
                            <div className='story-btns'>
                                <button>Payments</button>
                                <button>Global Market</button>
                            </div>
                            <p>We have increased our workflows effiency by 15% IDFlow .</p>
                            <div className='lq-div'>
                                <h3>LIV<span>Q</span>UIK</h3>
                                <button>View Complete Story</button>
                            </div>
                            <img src="https://stg-api.idflow.com/v1/files/g0VF5ZwBHOKyvDnWZLmoI" />
                        </div>
                    </div>
                </div>

                {/* Frequently used Microservices */}
                <div className='used-microservices'>
                    <h3 className='mt-4 pb-2'>Frequently Used Microservices</h3>
                    <div className='row'>

                        <div className='col-lg-6'>
                            <div className='used-left'>

                                <img src='https://stg-api.idflow.com/v1/files/kISCPjLdrSJTDx82p7uG2' className='explore-gif' />
                                <div className='microservice'>
                                    <img src='https://stg-api.idflow.com/v1/files/kUXAwaKhcPWCH4Xm1lSaV' />
                                    <h5 className='mb-0'>Alternate Score</h5>
                                    <p>Lorem ipsum dolor sit amet consectetur. Tincidunt pellentesque amet tempor in a commodo.</p>
                                    <button className='new-button'>Try it Out</button>

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='existing-flow frequently'>
                                <img src='https://stg-api.idflow.com/v1/files/kUXAwaKhcPWCH4Xm1lSaV' />
                                <h5 className='mb-0'>Alternate Score</h5>
                                <p className='mb-0'>Lorem ipsum dolor sit amet consectetur.</p>
                                <button className='new-button'>Try it Out</button>
                            </div>
                            <div className='frequently-cards'>
                                <div className='existing-flow '>
                                    <img src='https://stg-api.idflow.com/v1/files/kUXAwaKhcPWCH4Xm1lSaV' />
                                    <h5 className='mb-0'>Alternate Score</h5>
                                    <p className='mb-0'>Lorem ipsum dolor sit amet consectetur. Tincidunt pellentesque amet tempor in a commodo.</p>
                                    <button className='new-button'>Try it Out</button>
                                </div>
                                <div className='existing-flow '>
                                    <img src='https://stg-api.idflow.com/v1/files/kUXAwaKhcPWCH4Xm1lSaV' />
                                    <h5 className='mb-0'>Alternate Score</h5>
                                    <p className='mb-0'>Lorem ipsum dolor sit amet consectetur. Tincidunt pellentesque amet tempor in a commodo.</p>
                                    <button className='new-button'>Try it Out</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

