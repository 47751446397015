import axios from "axios";

const ACCESS_TOKEN_KEY = "accessToken";

export function logoutEvent(){
  let logoutEvent = new CustomEvent("logoutEvent", { bubbles: true});
  window.dispatchEvent(logoutEvent);
}

export const getSubDomain = (domain,pattern) => {
  if(domain.match(pattern)[1])
  {
   return domain.match(pattern)[1].replace('-', '');
  }
  else
  {
   return "m2p";
  }
}

export const BASE_URL = ()=>{
  const stg_pattern = /^([a-zA-Z]+-)?dev-workflow\.m2pfintech\.(com|dev)?$/
  const uat_pattern = /^([a-zA-Z]+-)?uat-workflow\.m2pfintech\.(com|dev)?$/
  const prod_pattern = /^([a-zA-Z]+-)?workflow\.m2pfintech\.(com|dev)?$/
  let hostname = window.location.host;
  if(stg_pattern.test(hostname) || uat_pattern.test(hostname) || prod_pattern.test(hostname)){
  let COM_URL = "";
    if(stg_pattern.test(hostname)){
      COM_URL = "https://" + getSubDomain(hostname,stg_pattern) +".idflow.site/api/";
    }
    else if(uat_pattern.test(hostname)){
      COM_URL = "https://" + getSubDomain(hostname,uat_pattern) +".idflow.com/api/";
    }
    else if(prod_pattern.test(hostname)){
      COM_URL = "https://" + getSubDomain(hostname,prod_pattern) +".idflow.com/api/";
    }
    console.log(COM_URL);
    return COM_URL;
  }
  else
  {
    let COM_URL = "https://stg-api.idflow.com/";
    let hostname = window.location.host;
    return hostname.endsWith("site")  ? "/api/": COM_URL;
  }
}

export function accessToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  }
  

// let origin = window?.location?.origin;
const instance = axios.create({
  baseURL: BASE_URL(),
});

instance.defaults.headers.common["Content-Type"] = "application/json";
instance.defaults.headers.common['Authorization'] = accessToken(),


instance.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      logoutEvent();
      let getModal = document.querySelector('.bd-example-modal-sm')
      if (getModal) {
        getModal.style.display = "block"
        getModal.classList.add('show')
      }
    }
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      logoutEvent();
      console.log(error.response.status)
      let getModal = document.querySelector('.bd-example-modal-sm')
      if (getModal) {
        getModal.style.display = "block"
        getModal.classList.add('show')
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
