import { useEffect, useState } from "react";
import { globalProtectedRouteApi } from "./api/common";


export const isLoggedInUser = () =>{
    return false
}

export const ProtectedRoute = ({children}) =>{

    const [loading, setLoading] = useState(true);

    useEffect(() =>{
        let g = async () =>{
            setLoading(true)
            await globalProtectedRouteApi();
            setLoading(false)
        }

        g();
    }, [])

    return loading? undefined: children;

}