import { redirect } from "react-router-dom";
import Axios from './axios';
import {logoutEvent} from './axios';
import { getSubDomain } from "./axios";

// export const BASE_URL = 

export const BASE_URL = ()=>{
  const stg_pattern = /^([a-zA-Z]+-)?dev-workflow\.m2pfintech\.(com|dev)?$/
  const uat_pattern = /^([a-zA-Z]+-)?uat-workflow\.m2pfintech\.(com|dev)?$/
  const prod_pattern = /^([a-zA-Z]+-)?workflow\.m2pfintech\.(com|dev)?$/
  let hostname = window.location.host;
  if(stg_pattern.test(hostname) || uat_pattern.test(hostname) || prod_pattern.test(hostname)){
  let COM_URL = "";
    if(stg_pattern.test(hostname)){
      COM_URL = "https://" + getSubDomain(hostname,stg_pattern) +".idflow.site/api/";
    }
    else if(uat_pattern.test(hostname)){
      COM_URL = "https://" + getSubDomain(hostname,uat_pattern) +".idflow.com/api/";
    }
    else if(prod_pattern.test(hostname)){
      COM_URL = "https://" + getSubDomain(hostname,prod_pattern) +".idflow.com/api/";
    }
    console.log(COM_URL);
    return COM_URL;
  }
  else
  {
    let COM_URL = "https://stg-api.idflow.com/";
    let hostname = window.location.host;
    return "/api/";
    // return hostname.endsWith("com")  ? COM_URL : "/api/";
  }
}

// TODO change to static /api/ if kubernetes ready for COM

const ACCESS_TOKEN_KEY = "accessToken";

export function accessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export function setAccessToken(token) {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
}
export function clearAccessToken(token) {
  localStorage.clear();
}

export function universalError(err) {
  const status_code = err.response.status;
  if (status_code === 401) {
    logoutEvent();
    return redirect("/login");
  }
}

export const METHODS = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
};

export const request = async (method, v, path, body) => {
  // const response = await Axios({
  //   url:  v + path,
  //   method: method,
  //   headers: {
  //     Authorization: "Bearer " + accessToken(),
  //   },
  //   data: body,
  // });
  try {
    const response = await Axios({
      url: v + path,
      method: method,
      headers: {
        Authorization: "Bearer " + accessToken(),
      },
      data: body,
    });

    return response.data;
  } catch (err) {
    throw err;
  }  

};

export const requestFile = async (method, v, path, body) => {
  try {
    const response = await Axios({
      url: v + path,
      method: method,
      headers: {
        Authorization: "Bearer " + accessToken(),
        "Content-Type": "multipart/form-data",
      },
      data: body,
    });
    
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const globalCommonRequest = async (method, path, body) => {
  return await request(method, "v1", path, body);
};
export const globalCommonRequestFile = async (method, path, body) => {
  return await requestFile(method, "v1", path, body);
};

export const globalCommonRequestV1 = (method, path, body, onSuccess, onFailure) => {
  return request(method, "v1", path, body, onSuccess, onFailure);
};

export const globalImageRequest = async (v, path, body) => {
  try {
    // const url = BASE_URL + v + path;
    const formData = new FormData();
    formData.append("file", body, body.name);
    const response = await Axios.post(v+"v1"+path, formData, {
      headers: {
        accept: "*/*",
        Authorization: "Bearer " + accessToken(),
        "Content-Type": "multipart/form-data",
      },
      data: body,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const globalProtectedRouteApi = async () => {
  try{
      let res = await globalCommonRequestV1("GET", "/protectedRoute");
  } catch (e){
    let status_code = e?.response?.status;
      return status_code
  }
}