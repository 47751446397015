import React from 'react'
import { ComingSoon } from './comingSoon'

export const DashboardPage = () => {
    return (
        <div>
            <ComingSoon />
        </div>
    )
}

