import React from 'react'
import { BiSearch } from 'react-icons/bi'

export const IdflowSearch = ({placeholder, name, onChange, value, className}) => {
  return (
    <div className={`${className} search-bar`} style={{className}}>
        <BiSearch className="icon-search" size="1.2rem" />
        <input
        type="text"
        placeholder={placeholder}
        name={name}
        id="sea"
        onChange={onChange}
        value={value}
        />
  </div>
)
}

