import React from 'react'
import './global-components.css';

export const PageNotFound = () => {
    return (
        <div>
            <div className='comingsoonWrapper'>
                <div className='comingsoonmain'>
                    <img src='https://idflow-workflow-images.s3.ap-south-1.amazonaws.com/404-error-image.svg' />
                    <h3>404 Error!</h3>
                    <p>The requested page is not found, in our server please check for alternative page.</p>
                    <button onClick={() => window.history.go(-1)}>Go Back</button>
                </div>
            </div>
        </div>
    )
}

