import { METHODS, globalCommonRequest } from "./common";

export const Resource = (resource_name) => {

    const path = (id) => `/${resource_name}/` + id

    return {
        getAll: async (skip = 0, limit = 100, filter = undefined) => {
            return await globalCommonRequest(METHODS.get, path(`?skip=${skip}&limit=${limit}`) + (filter ? `&filter=${filter}` : ""), {})
        },
        get: async (id) => {
            return await globalCommonRequest(METHODS.get, path(id), {})
        },
        create: async (resource) => {
            return await globalCommonRequest(METHODS.post, path(''), resource)
        },
        update: async (resource) => {
            return await globalCommonRequest(METHODS.put, path(resource.id || resource._id), resource)
        },
        delete: async (id) => {
            return await globalCommonRequest(METHODS.delete, path(id), {})
        },
    }
}
